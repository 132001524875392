import * as React from "react";
import { useEffect, useState } from "react";
import SocialBlock from "../SocialBlock";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "../Link";
import { FunctionComponent } from "react";
import ExternalLink from "../ExternalLink";
import AdChoices from "../../../../US/src/web-common/components/AdChoices";
import { useInView } from "react-intersection-observer";
import { Logo } from "../../images/icons/logo";
import { SanityNavigation, SanityNavItem, SanitySocialMediaInformation, InternalLink } from "../../types/SanityTypes";
// import { LocalizedContext } from "../../services/LocalizedContextService";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import UnileverLogo from "../../images/icons/unileverLogoWhite.png";
import NewsLetterBlock from "../NewsletterBlock";
import Newsletterpopup from "../Newsletterpopup";
import Accessibility from "../Accessibility";
// import Evidon from "../Evidon";
import { useStaticQuery, graphql } from "gatsby";
import "./styles.scss";

export interface FooterInterface {
  data: {
    sanitySocialMediaInformation?: SanitySocialMediaInformation;
    sanityLocationSelector?: InternalLink;
    sanityFooter?: SanityNavigation;
    sanityLabels?: {
      footerLegalDisclaimer?: string;
      footerCopyright?: string;
      adChoicesText?: string;
      countrySelectorLabel?: string;
    };
    pageTranslations: {
      language: string;
      urlPath: string;
    }[];
  };
  hideNewsLetterFooterBlock?: boolean;
  hideNewsletterPopUp?: boolean;
}

const Footer: FunctionComponent<FooterInterface> = ({
  data: { sanityFooter, sanitySocialMediaInformation, sanityLabels },
  hideNewsLetterFooterBlock = false,
  hideNewsletterPopUp = false
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "10px 0px"
  });
  const socialLinks = sanitySocialMediaInformation?.socialLinks || [];
  const socialLabel = sanitySocialMediaInformation?.name || "";
  const navItems = sanityFooter?.navItems || [];
  // const { sanityAccessibility } = useContext(LocalizedContext);
  const { additionalLanguagesConfig } = useSiteMetadata();
  const { country, languageString } = additionalLanguagesConfig[0];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [_, country] = "EN_US".split("_");
  const newsletterBlock = sanityFooter?.newsletterBlock || {};
  const newsletterPopUp = sanityFooter?.newsletterPopUp || {};
  const [isPopupNeeded, setIsPopupNeeded] = useState(false);
  const [enableOneTrust, setEnableOneTrust] = useState(false);
  const signUpForm = useStaticQuery(graphql`
    {
      allSanitySignUp {
        nodes {
          _rawFormPrivacyInfo(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);
  useEffect(() => {
    const urlWeb = new URL(window.location.href);
    const enableOneTrustParam = urlWeb.searchParams.get("enableOneTrust");
    setEnableOneTrust(enableOneTrustParam != "false");
    setTimeout(() => {
      const lastShownDate = typeof window !== "undefined" && window.localStorage.getItem("lastPopupShownDate");
      setIsPopupNeeded((Date.now() - Number(lastShownDate)) / (24 * 60 * 60 * 1000) > 15);
    }, 10000);
  }, []);

  const isLargeDeviceScreen = (typeof window !== "undefined" && window.matchMedia("(min-width: 992px)")) || false;

  return (
    <footer id="footer" className="footer" data-testid="footer" ref={ref}>
      {sanityFooter?.newsletterBlock && !hideNewsLetterFooterBlock && (
        <NewsLetterBlock
          {...newsletterBlock}
          inView={inView}
          formPrivacyInfo={signUpForm.allSanitySignUp.nodes[0]._rawFormPrivacyInfo}
        />
      )}
      {sanityFooter?.newsletterPopUp && !hideNewsletterPopUp && isPopupNeeded && (
        <Newsletterpopup {...newsletterPopUp} />
      )}
      <Container className="position-relative py-4 py-md-5">
        <Row className="justify-content-between px-3 px-md-0">
          <div className="mr-lg-5 pl-0 pr-0 col-md-3 col-lg-2">
            <div className="d-flex justify-content-between p-0 col-sm-12">
              <Link to="/" aria-label="Tresemme logo">
                <Logo />
              </Link>
            </div>
            <SocialBlock socialLabel={socialLabel} socialLinks={socialLinks.sort()} />
          </div>
          <div className="px-0 px-md-2 footer__navigation-container col-md-7 col-lg-6">
            <ul className="footer__navigation">
              {navItems.map((item: SanityNavItem) => (
                <li key={item.navL1.name} className="footer__navigation-link">
                  {item.navL1.externalLink ? (
                    <ExternalLink
                      markKey={item.navL1.name}
                      link={item.navL1.externalLink}
                      name={item.navL1.name}
                      noopener={true}
                      nofollow={true}
                    />
                  ) : (
                    <Link
                      to={item.navL1.landingPage ? item.navL1.landingPage?.slug?.current : item.navL1.path}
                      aria-label={item.navL1.name}
                      title={item.navL1.name}
                    >
                      {item.navL1.name}
                    </Link>
                  )}
                </li>
              ))}
              {enableOneTrust && isLargeDeviceScreen && !isLargeDeviceScreen.matches && (
                <li className="footer__navigation-link d-lg-none">
                  <AdChoices />
                </li>
              )}
            </ul>
          </div>
          {enableOneTrust && isLargeDeviceScreen && isLargeDeviceScreen.matches && (
            <div className="d-lg-block col-md-2">
              <AdChoices />
            </div>
          )}
        </Row>
        <Row className="mt-md-5">
          <div className="d-flex align-items-center col-sm-6 col-12 order-sm-1 order-2">
            <div className="footer-unilever-logo">
              <a
                href="https://www.unileverusa.com/"
                rel="noreferrer"
                aria-label={`UnileverLogo`}
                title={`UnileverLogo`}
              >
                <img src={UnileverLogo} alt="Unilever logo" width={58} height={58} />
                <Accessibility openNewWindow={true} />
              </a>
            </div>
            <div className="footer__legal">
              {sanityLabels && (
                <span className="footer__legal-copyright">
                  {sanityLabels?.footerCopyright}
                </span>
              )}
              <br />
              {sanityLabels?.footerLegalDisclaimer && (
                <span className="footer__legal-disclaimer">{sanityLabels?.footerLegalDisclaimer}</span>
              )}
            </div>
          </div>
          <div className="col-sm-6 order-1 col-12">
            <div className="footer__location justify-content-sm-end">
              <p className="footer__location-label">Location</p>
              <p className="footer__location-value">
                {country}, {languageString} |&nbsp;
                <ExternalLink
                  markKey={country}
                  link="https://www.tresemme.com/uk/location-selector.html"
                  name="Change location"
                  noopener={true}
                  nofollow={true}
                />
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
