import React from "react";

export const Peta = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" x="0px" y="0px"
         viewBox="0 0 100 37.8" enableBackground="new 0 0 100 37.8">
      <path fill="#FFFFFF" d="M19,0H9.7L0,36h8l3.9-14l-0.4,1.8c5.4,0.4,17.1,0.5,18.8-12.6C32-1.4,19,0,19,0z M19.9,15.1
        c-2.7,2.6-6.6,1.6-6.6,1.6l1.1-4l1.5-5.3c0.5-0.1,4.4-0.4,5.5,0.4C22.7,8.8,21.7,13.4,19.9,15.1z M54.3,2.8c-0.6-1-1.6-1.8-2.7-2.2
        c-6.8-2.3-12.5,3-16.4,8.1c-3.9,5.4-6.3,11.3-6.5,18.3c0.2,3.9,1.2,8.3,5.2,9.9c6.6,1.6,11.4-3.9,14.8-8.5c0.3-0.6,1.1-1.3,1.1-1.9
        c-0.3-0.1-0.5-0.4-0.9-0.3c-0.8,1.1-1.5,2.2-2.5,3.1c-2.3,2.2-5.2,3.9-8.5,3.2c-3.7-1.6-3.8-5.9-3.4-9.6c0.3-1.4,0.3-2.9,1-4.2
        l2.5-0.8c5.5-1.8,11.8-2.2,15.5-7.6C54.9,8.2,55.8,5.1,54.3,2.8z M42.3,14.9c-7.4,2.4-5.6,1.4-5.6,1.4S42.4-0.6,48.1,1.4
        C54,3.4,49.7,12.5,42.3,14.9z M58.5,0.3l23.7-0.1l-2,7.6L72.4,8l-7.9,28h-8.7l7.6-28.1h-7.1C56.4,7.9,58.5,0.3,58.5,0.3z M89.1,0.2
        L71.9,35.9h8.8l2.7-5.9h8.1v5.8h7.9V0.2C99.3,0.2,89.1,0.2,89.1,0.2z M86.7,23l4.7-10.2v-0.1v10.4h-4.7V23z"/>
</svg>
  );
};
