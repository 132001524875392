import React, { FunctionComponent, useState } from "react";
import { Card, Container, Row } from "react-bootstrap";
import Picture from "../Picture";
import NewsletterFormElement from "../NewsletterFormElements";
import "./styles.scss";

import { SanityNewsletterBlock as NewsLetterBlockInterface } from "../../types/SanityTypes";
import RichText from "../RichText";

interface NewsletterBlockPropsInterface extends NewsLetterBlockInterface {
  inView?: boolean;
  formPrivacyInfo?: any;
}

const NewsLetterBlock: FunctionComponent<NewsletterBlockPropsInterface> = ({
  backgroundImage,
  title,
  subtitle,
  inView,
  formPrivacyInfo
}) => {
  const [formStatus, setFormStatus] = useState("idle");
  const backgroundBannerImage = {
    src: `${backgroundImage?.asset?.url}?auto=format`,
    alt: `${backgroundImage?.alt}`,
    sources: [
      {
        srcset: [
          `${backgroundImage?.asset?.url}?w=400&h=450&fit=crop&auto=format&q=80 1x`,
          `${backgroundImage?.asset?.url}?w=800&h=900&fit=crop&auto=format&q=80 2x`
        ],
        media: `(max-width: 639px)`
      },
      {
        srcset: [
          `${backgroundImage?.asset?.url}?w=600&h=50&fit=crop&auto=format&q=80 1x`,
          `${backgroundImage?.asset?.url}?w=1200&h=1000&fit=crop&auto=format&q=80 2x`
        ],
        media: `(min-width: 640px) and (max-width: 1023px)`
      },
      {
        srcset: [
          `${backgroundImage?.asset?.url}?w=720&h=350&fit=max&auto=format&q=80 1x`,
          `${backgroundImage?.asset?.url}?w=1440&h=700&fit=max&auto=format&q=80 2x`
        ],
        media: `(min-width: 1024px) and (max-width: 1439px)`
      },
      {
        srcset: [
          `${backgroundImage?.asset?.url}?w=815&h=400&fit=max&auto=format&q=80 1x`,
          `${backgroundImage?.asset?.url}?w=1630&h=800&fit=max&auto=format&q=80 2x`
        ],
        media: `(min-width: 1440px)`
      }
    ],
    maintainAspectRatio: false,
    size: {
      width: "100%",
      height: "100%"
    },
    lqip: backgroundImage?.asset?.metadata?.lqip
  };

  return (
    <Card className="newsletter border-0">
      {backgroundImage?.asset?.url && (
        <div className="newsletter__backgroundImage w-100 h-100 position-absolute overflow-hidden">
          <Picture
            src={backgroundBannerImage.src}
            alt={backgroundBannerImage.alt}
            sources={backgroundBannerImage.sources}
            maintainAspectRatio={backgroundBannerImage.maintainAspectRatio}
            size={backgroundBannerImage.size}
            lqip={backgroundBannerImage.lqip}
            loading="lazy"
            className="h-100 w-100"
            inView={inView}
          />
        </div>
      )}
      <Container className="px-3 py-5">
        <Row className="d-flex justify-content-between">
          <div className="newsletter__container col-md-6 d-flex flex-column justify-content-center">
            <h2 className="newsletter__title text-uppercase text-center text-md-left">{title}</h2>
            <p className="newsletter__subtitle text-center text-md-left">{subtitle}</p>
          </div>
          <div className="newsletter__form col-md-5">
            <NewsletterFormElement formStatus={formStatus} setFormStatus={setFormStatus}>
              <RichText data={formPrivacyInfo} />
            </NewsletterFormElement>
          </div>
        </Row>
      </Container>
    </Card>
  );
};

export default NewsLetterBlock;
