import React, { FunctionComponent, useContext } from "react";
import { Instagram } from "../../images/icons/instagram";
import { Twitter } from "../../images/icons/twitter";
import { Youtube } from "../../images/icons/youtube";
import { Facebook } from "../../images/icons/facebook";
import { Tiktok } from "../../images/tiktok";

import "./styles.scss";

import { ReactComponent } from "*.svg";
import { SocialMediaChannel } from "../../types/SanityTypes";
import Accessibility from "../Accessibility";
// import { LocalizedContext } from "../../services/LocalizedContextService";
import { Peta } from "../../images/icons/peta";
type SocialBlockProps = {
  socialLabel: string;
  socialLinks: ReadonlyArray<SocialMediaChannel>;
};

const SocialBlock: FunctionComponent<SocialBlockProps> = props => {
  const socialMedia = props.socialLinks;
  const socialLabel = props.socialLabel;
  // const { sanityAccessibility } = useContext(LocalizedContext);

  const socialIcons: { [char: string]: typeof ReactComponent } = {
    facebook: Facebook,
    instagram: Instagram,
    twitter: Twitter,
    youtube: Youtube,
    tiktok: Tiktok,
    peta: Peta,
    default: Facebook
  };

  const renderItems = () => {
    return socialMedia.map((item: SocialMediaChannel) => {
      const Component = socialIcons[item.channel?.toLowerCase()] || socialIcons.default;
      const id = item.channel?.toLowerCase().replace(/ /g, "-");
      return (
        <li key={item.channel} className="mb-md-2">
          <a
            href={item?.url}
            className="social-icon"
            rel="noreferrer"
            data-analytics-event48=""
            data-testid={id}
            title={`${item.channel}`}
            aria-label={`${item.channel}`}
          >
            <Component />
            <Accessibility location={item.channel} openNewWindow={false} />
          </a>
        </li>
      );
    });
  };

  if (!socialMedia) return null;

  return (
    <React.Fragment>
      <p className="social__title font-weight-bold text-uppercase">{socialLabel}</p>
      <ul className="social__platforms d-flex justify-content-between flex-wrap mb-4 mb-sm-5" data-testid="social-block">
        {renderItems()}
      </ul>
    </React.Fragment>
  );
};

export default SocialBlock;
