import React from "react";

export const Twitter = (props: React.ComponentProps<any>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
      <g id="icomoon-ignore"></g>
      <path
        fill="#ffffff"
        d="M1022.016 194.957c-37.758 16.596-78.077 27.903-120.528 33.066 43.262-26.068 76.541-67.154 92.284-116.176-40.574 23.679-85.543 40.915-133.413 50.515-38.227-40.915-92.711-66.514-153.21-66.514-115.92 0-209.911 93.991-209.911 209.783 0 16.639 1.92 32.639 5.419 47.955-174.5-8.235-329.159-92.028-432.665-218.7-18.218 30.804-28.415 66.6-28.415 105.596 0 72.957 37.118 137.082 93.351 174.756-34.431-1.109-66.813-10.581-95.057-26.282v2.603c0 101.756 72.232 186.616 168.356 205.943-17.62 4.736-36.222 7.296-55.294 7.296-13.396 0-26.239-1.28-39.081-3.669 26.922 83.325 104.316 144.079 196.429 145.786-71.677 56.275-162.51 89.809-260.341 89.809-16.639 0-33.236-0.981-49.918-2.859 93.393 59.475 203.426 94.247 322.418 94.247 386.288 0 597.266-319.816 597.266-596.711 0-8.917 0-17.919-0.64-26.879 41.001-29.396 76.797-66.557 104.956-108.71z"
      ></path>
    </svg>
  );
};
