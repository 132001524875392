import React, { useState, useLayoutEffect, FunctionComponent, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Close } from "../../images/icons/close";
import { getCookie, setCookie } from "../../utils/helper";
import Button from "../Button";
import NewsletterFormElement from "../NewsletterFormElements";
import { SanityNewsletterPopUp } from "../../types/SanityTypes";
import NewsletterResponse from "../NewsletterResponse";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event58 } from "../../analytics/event58";
import { event50 } from "../../analytics/event50";

import "./styles.scss";

const Newsletterpopup: FunctionComponent<SanityNewsletterPopUp> = props => {
  const [show, setShow] = useState(false);
  const [formStatus, setFormStatus] = useState("idle");
  const { closeNewsletterPopup } = useContext(LocalizedContext).sanityLabels?.ctaLabels || {};
  const handleClose = () => {
    window.isPopUpClosed = true;
    setShow(false);
    window.localStorage.setItem("lastPopupShownDate", String(Date.now()))
  };

  const { image, title, popUpTime = 5, popUpExpires } = props;
  const { sanityAccessibility } = useContext(LocalizedContext);

  useLayoutEffect(() => {
    setTimeout(() => {
      const popUpFormSubmitted = getCookie("popUpFormSubmitted");
      const urlWeb = new URL(window.location.href);
      const allowPopUp = urlWeb.searchParams.get('disablePopUp');
      const showPopup =
        (typeof popUpFormSubmitted === "undefined" || popUpFormSubmitted === "false") && !window.isPopUpClosed && allowPopUp !== 'true';
      setShow(showPopup);
    }, popUpTime * 1000);
  }, []);

  useEffect(() => {
    if (formStatus === "success") {
      event50();
      event58();
      setCookie({
        cookieKey: "popUpFormSubmitted",
        cookieValue: "true",
        expiryDays: popUpExpires || 15
      });
    }
  }, [formStatus]);

  const handleFormReset = () => {
    setFormStatus("idle");
  };

  return (
    <>
      <Modal
        dialogClassName="modal-dialog modal-lg p-0 text-break container newsletterPopup__container modal-dialog-centered"
        show={show}
        onHide={handleClose}
        animation={false}
        role="dialog"
        aria-labelledby="dialogPopupOpen"
        centered
      >
        <Modal.Body className="modal-body">
          <span id="dialogPopupOpen" className="sr-only">The popup is open</span>
          <div className="newsletterPopup__body row mx-0 d-flex">
            {formStatus === "idle" && (
              <div className="newsletterPopup__form col-md-6">
                <h2 className="newsletterPopup__title my-5 text-center text-sm-left">
                  {title || "Get exclusive offers, announcements and product news when you sign up today!"}
                </h2>
                <NewsletterFormElement formStatus={formStatus} setFormStatus={setFormStatus} overLayPopUp={true} />
                <p className="newsletterPopup__policytext my-5">
                  Be the first to know about exciting offers, product updates and more from TRESemmé and other{" "}
                  <a
                    href="https://www.unilever.com/brands/"
                    title={`Unilever Brands ${sanityAccessibility?.newWindow}`}
                    aria-label={`Unilever Brands ${sanityAccessibility?.newWindow}`}
                    rel="noreferrer"
                  >
                    Unilever Brands
                  </a>
                  . For further information, please refer to our{" "}
                  <a
                    href="https://www.unilevernotices.com/usa/english/privacy-notice/notice.html"
                    title={`Privacy Notice ${sanityAccessibility?.newWindow}`}
                    aria-label={`Privacy Notice ${sanityAccessibility?.newWindow}`}
                    rel="noreferrer"
                  >
                    Privacy Notice
                  </a>
                  .
                </p>
              </div>
            )}
            {formStatus === "success" && (
              <div className="newsletterPopup__success col-md-6 d-flex justify-content-center align-items-center">
                <h2>THANKS FOR SIGNING UP!</h2>
              </div>
            )}
            {formStatus === "error" && (
              <div className="newsletterPopup__error col-md-6 d-flex justify-content-center align-items-center">
                <NewsletterResponse
                  title="Failure"
                  description="There was an error trying to send your message. Please try again"
                  handleFormReset={handleFormReset}
                  buttonVariant={"primary"}
                />
              </div>
            )}
            <div
              className="col-md-6 d-none d-sm-flex newsletterPopup__image"
              style={{
                backgroundImage: `url(${image?.asset?.url}??w=305&h=566&fit=max&auto=format&q=80)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: `${image?.asset?.metadata?.palette.darkMuted.background}`
              }}
            />
            <Button
              variant="primary"
              className="newsletterPopup__close align-items-center"
              onClick={handleClose}
            >{closeNewsletterPopup}</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Newsletterpopup;