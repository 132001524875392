import React from "react";

export const Tiktok = (props: React.ComponentProps<any>) => {
  return (
    <svg viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.59351 38.273L8.71822 38.6251C8.70225 38.5848 8.65662 38.4616 8.59351 38.273ZM13.5288 30.2936C13.7479 28.4015 14.4916 27.3422 15.8931 26.2563C17.8984 24.7856 20.4033 25.6175 20.4033 25.6175V20.6822C21.0123 20.6659 21.6215 20.7036 22.2239 20.7947V27.146C22.2239 27.146 19.7197 26.3141 17.7144 27.7856C16.3136 28.8707 15.5684 29.9308 15.3501 31.8228C15.3433 32.8502 15.5281 34.1932 16.379 35.3544C16.1686 35.2418 15.9542 35.1136 15.7357 34.9696C13.8612 33.6563 13.5197 31.6859 13.5288 30.2936ZM32.5668 11.5567C31.1873 9.97957 30.6657 8.38718 30.4771 7.26855H32.2124C32.2124 7.26855 31.8664 10.2016 34.3881 13.086L34.4231 13.1248C33.7434 12.6788 33.1202 12.1523 32.5668 11.5567ZM40.9264 16.0282V22.2518C40.9264 22.2518 38.712 22.1613 37.0732 21.7263C34.785 21.1179 33.3143 20.1848 33.3143 20.1848C33.3143 20.1848 32.2983 19.5195 32.2162 19.4731V32.3247C32.2162 33.0403 32.0284 34.8274 31.4558 36.3179C30.7082 38.2677 29.5546 39.5475 29.3425 39.8091C29.3425 39.8091 27.9394 41.5391 25.4642 42.7041C23.233 43.7551 21.2741 43.7285 20.6885 43.7551C20.6885 43.7551 17.3022 43.895 14.2551 41.8289C13.5962 41.3735 12.9811 40.8577 12.4178 40.2882L12.433 40.2996C15.4809 42.3657 18.8665 42.2258 18.8665 42.2258C19.4528 42.1992 21.4117 42.2258 23.6421 41.1749C26.1151 40.0099 27.5204 38.2798 27.5204 38.2798C27.7303 38.0182 28.8892 36.7384 29.6337 34.7878C30.2048 33.2981 30.3942 31.5103 30.3942 30.7947V17.9446C30.4763 17.9917 31.4915 18.6571 31.4915 18.6571C31.4915 18.6571 32.963 19.5909 35.2512 20.1985C36.8907 20.6335 39.1044 20.724 39.1044 20.724V15.8472C39.8618 16.0244 40.5074 16.0723 40.9264 16.0282Z"
        fill="white"
      />
      <path
        d="M39.1097 15.8691V20.744C39.1097 20.744 36.8968 20.6535 35.2578 20.2185C32.9704 19.6102 31.4994 18.6772 31.4994 18.6772C31.4994 18.6772 30.4846 18.0119 30.4025 17.9647V30.817C30.4025 31.5325 30.2147 33.3202 29.6423 34.8098C28.895 36.7603 27.7418 38.04 27.5297 38.3016C27.5297 38.3016 26.1264 40.0315 23.6527 41.1964C21.4231 42.2473 19.4648 42.2207 18.8787 42.2473C18.8787 42.2473 15.4943 42.3872 12.4475 40.3212L12.4323 40.3098C12.1106 39.9847 11.8078 39.6415 11.5254 39.2817C10.5531 38.0446 9.95708 36.5816 9.80733 36.1641C9.80707 36.1623 9.80707 36.1605 9.80733 36.1588C9.56635 35.4334 9.06006 33.6913 9.12924 32.004C9.25163 29.027 10.2551 27.1998 10.5204 26.742C11.223 25.4937 12.1369 24.3768 13.2213 23.4411C14.1783 22.6337 15.263 21.9912 16.4309 21.5401C17.6934 21.0105 19.0454 20.7266 20.4143 20.7037V25.6387C20.4143 25.6387 17.9102 24.8098 15.9063 26.2774C14.5053 27.3633 13.7618 28.4225 13.5429 30.3144C13.5338 31.7066 13.8751 33.6768 15.7475 34.9908C15.9659 35.1353 16.1803 35.2635 16.3906 35.3756C16.7177 35.8193 17.1158 36.206 17.5689 36.52C19.3979 37.7282 20.9305 37.8126 22.8902 37.0279C24.197 36.5032 25.1807 35.3208 25.6368 34.0107C25.9234 33.1925 25.9196 32.369 25.9196 31.5173V7.29102H30.4808C30.6693 8.40956 31.1908 10.0018 32.5698 11.5789C33.1229 12.1744 33.746 12.7008 34.4254 13.1468C34.6261 13.3636 35.6523 14.435 36.9698 15.0927C37.651 15.4327 38.369 15.6932 39.1097 15.8691Z"
        fill="white"
      />
      <path
        d="M16.3994 21.4983C15.2311 21.9498 14.1461 22.5928 13.1888 23.4011C12.1037 24.3398 11.1897 25.46 10.4877 26.7119C10.2223 27.1686 9.21855 28.9991 9.09612 31.9788C9.02692 33.6677 9.53337 35.4114 9.77443 36.1375C9.77417 36.1393 9.77417 36.1411 9.77443 36.1429C9.92652 36.5569 10.5204 38.0213 11.493 39.2634C11.7756 39.6235 12.0785 39.9671 12.4002 40.2925C11.3692 39.5788 10.4497 38.7159 9.67177 37.7321C8.70753 36.5044 8.11287 35.0552 7.95774 34.6275C7.95755 34.6245 7.95755 34.6214 7.95774 34.6184V34.613C7.71592 33.8877 7.20794 32.1432 7.27866 30.452C7.40109 27.4723 8.40488 25.6433 8.67027 25.1851C9.37206 23.9331 10.286 22.8128 11.3714 21.8743C12.3284 21.0658 13.4135 20.4227 14.582 19.9715C15.3108 19.6688 16.07 19.4455 16.8465 19.3055C18.0168 19.101 19.2121 19.0833 20.3879 19.253V20.661C19.0173 20.6835 17.6636 20.9676 16.3994 21.4983Z"
        fill="#D2D2D2"
      />
      <path
        d="M30.4761 7.28709H25.9104V31.5194C25.9104 32.3713 25.9104 33.1927 25.6273 34.0133C25.1661 35.323 24.1853 36.5057 22.8779 37.0305C20.9154 37.8184 19.3813 37.731 17.5512 36.5224C17.0969 36.2098 16.6973 35.8243 16.3687 35.3816C17.9279 36.2129 19.3235 36.1984 21.0524 35.5048C22.359 34.98 23.3414 33.7973 23.801 32.4869C24.0886 31.6685 24.0848 30.8448 24.0848 29.9937V5.75684H30.3894C30.3894 5.75684 30.3186 6.3592 30.4761 7.28709ZM39.11 14.5185V15.867C38.3699 15.6908 37.6524 15.4303 36.9717 15.0905C35.653 14.4326 34.6257 13.361 34.4248 13.1442C34.6579 13.2972 34.8998 13.4363 35.1492 13.561C36.7526 14.3611 38.3316 14.5999 39.11 14.5185Z"
        fill="#D2D2D2"
      />
    </svg>
  );
};
